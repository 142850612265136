import { Box, Typography, Button } from "@mui/material";
import React from "react";
import PricingBox from "./pricing-box";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { useNavigate } from "react-router";

const Pricing = () => {
  const navigate = useNavigate();
  const handleTryForFreeButton = () => {
    navigate("/signup");
  };
  return (
    <Box
      id="Pricing"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ px: 10, py: 20 }}
    >
      <Typography
        sx={{ fontSize: 40, fontWeight: 800, textAlign: "center", mb: 10 }}
      >
        {" "}
        Pricing{" "}
      </Typography>
      <PricingBox
        title={"Free"}
        price={"0"}
        button={
          <Button
            onClick={handleTryForFreeButton}
            sx={{
              bgcolor: "#D4FF71",
              color: "#000",
              borderRadius: 100,
              px: 3,
              "&:hover": {
                bgcolor: "rgba(212, 255, 113, 0.8)",
              },
            }}
          >
            Try for free
          </Button>
        }
        description={
          <Box>
            <Typography
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ pb: 2, fontSize: 16 }}
            >
              <DoneRoundedIcon sx={{ pr: 2, fontSize: 18 }} />
              Full access to 10 lessons across all 10 subjects
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default Pricing;
