import { Box, Typography } from "@mui/material";
import React from "react";
import LinkedIn from "../../../assets/linkedin.svg";
import Twitter from "../../../assets/twitter.svg";
import Instagram from "../../../assets/instagram.svg";

const Footer = () => {
  return (
    <Box
      sx={{
        pt: { md: 20, sm: 5, xs: 4 },
        pb: 10,
        mx: { md: 20, sm: 5, xs: 2 },
      }}
    >
      <Box
        display="flex"
        flexDirection={{ md: "row", xs: "column" }}
        justifyContent="space-between"
      >
        <Box sx={{ py: 2 }}>
          <Typography sx={{ color: "#687066", py: 1, fontSize: 13 }}>
            Curriculum
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            C (Programming Language)
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Advanced Programming
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Data Structures and Algorithms
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Discrete Mathematics
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Computer Architecture
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Computer Networking
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>Databases</Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Operating Systems
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Distributed Systems
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Languages and Compilers
          </Typography>
        </Box>
        <Box sx={{ py: 2 }}>
          <Typography sx={{ color: "#687066", py: 1, fontSize: 13 }}>
            Features
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>Beyond Syntax</Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            Active Learning
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>
            1-to-1 Guidance
          </Typography>
        </Box>
        <Box sx={{ py: 2 }}>
          <Typography sx={{ color: "#687066", py: 1, fontSize: 13 }}>
            Pricing
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>Free</Typography>
        </Box>
        <Box sx={{ py: 2 }}>
          <Typography sx={{ color: "#687066", py: 1, fontSize: 13 }}>
            Terms and Policies
          </Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>Terms of Use</Typography>
          <Typography sx={{ pb: 0.8, fontSize: 13 }}>Privacy Policy</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ pt: 15 }}
      >
        <Typography> SYNAPSE © 2024 </Typography>
        <Box display="flex" flexDirection="row">
          <a
            href="https://my.linkedin.com/company/synapselearning"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LinkedIn}
              alt="LinkedIn"
              width={24}
              style={{ marginRight: 10 }}
            />
          </a>
          <a
            href="https://x.com/synapse_tutor"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Twitter}
              alt="Twitter"
              width={24}
              style={{ marginRight: 10 }}
            />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
