import { Box, Typography, Button } from "@mui/material";
import React from "react";
import Navbar from "../../components/navbar";
import { useNavigate } from "react-router-dom";
import BackgroundNodes from "./background-nodes";
import Marquee from "react-fast-marquee";
import SubjectCard from "./subject-card";
import Features from "./features";
import Pricing from "./pricing";
import StartLearning from "./start-learn";
import Footer from "./footer";

const LandingPage = () => {
  const navigate = useNavigate();
  const handleTryForFreeButton = () => {
    navigate("/signup");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#111210",
      }}
    >
      <Navbar />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          pt: 15,
          px: { md: "inherit", sm: "inherit", xs: 2 },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: { md: 50, sm: 40, xs: 30 },
            textAlign: "center",
            color: "#FFFFFF",
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          Become a
          <span
            style={{
              backgroundColor: "#223013",
              borderRadius: 5,
              paddingLeft: 10,
              paddingRight: 10,
              marginLeft: 11,
              color: "#E3F7BA",
            }}
          >
            Better{" "}
          </span>
          <br />
          Software Engineer
        </Typography>
        <Typography
          sx={{
            py: 5,
            color: "#ECEEEC",
            textAlign: "center",
            fontSize: { md: 18, sm: 17, xs: 16 },
            px: { md: 35, sm: 10, xs: 1 },
          }}
        >
          AI tutor designed for software engineers ready to dive deep into
          low-level computer science concepts beyond frameworks and
          interview-prep.
        </Typography>
        <Button
          onClick={handleTryForFreeButton}
          sx={{
            bgcolor: "#D4FF71",
            color: "#000",
            borderRadius: 100,
            px: 3,
            "&:hover": {
              bgcolor: "rgba(212, 255, 113, 0.8)",
            },
          }}
        >
          Get Started
        </Button>
        <Typography sx={{ color: "#434842", mt: 4 }}>
          No credit card required
        </Typography>
      </Box>
      <Box id="Curriculum" sx={{ pt: 10 }}>
        <Marquee autoFill pauseOnHover direction="right" speed={35}>
          <SubjectCard subject={"C (Programming Language)"} />
          <SubjectCard subject={"Advanced Programming"} />
          <SubjectCard subject={"Computer Architecture"} />
          <SubjectCard subject={"Operating Systems"} />
          <SubjectCard subject={"Computer Networking"} />
        </Marquee>
        <Marquee autoFill pauseOnHover direction="left" speed={35}>
          <SubjectCard subject={"Databases"} />
          <SubjectCard subject={"Distributed Systems"} />
          <SubjectCard subject={"Languages and Compilers"} />
          <SubjectCard subject={"Data Structures and Algorithms"} />
          <SubjectCard subject={"Discrete Mathematics"} />
        </Marquee>
      </Box>
      <Features />
      <Pricing />
      <StartLearning />
      <Footer />
    </Box>
  );
};

export default LandingPage;
