import React from "react";
import { Alert, Box, Collapse, Button, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const TransitionAlert = () => {
  const [open, setOpen] = React.useState(true);
  return (
    <Box display="flex">
      {/* <Collapse in={open}> */}
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Alert
          icon={
            <WarningRoundedIcon
              fontSize="inherit"
              style={{ color: "#E3F7BA" }}
            />
          }
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" style={{ color: "#D4FF71" }} />
            </IconButton>
          }
          sx={{ fontSize: 13, bgcolor: "#223013", color: "#E3F7BA" }}
        >
          Double-click buttons if unresponsive.
        </Alert>
      </Slide>
      {/* </Collapse> */}
      {!open && (
        <Button
          disabled={open}
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            borderRadius: 100,
            py: 1.5,
            "&:hover": {
              color: "#D4FF71",
              background: "#111210",
              "& > *": {
                color: "#D4FF71",
              },
            },
          }}
        >
          <WarningRoundedIcon style={{ color: "#D4FF71" }} />
        </Button>
      )}
    </Box>
  );
};

export default TransitionAlert;
