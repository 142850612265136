import { Typography, Box, Grid } from "@mui/material";
import React from "react";
import VideoContainer from "../../../../components/video-container";

const FeatureBox = ({ title, description, videoSource }) => {
  return (
    <Grid
      display="flex"
      alignItems={{ md: "center", sm: "center", xs: "none" }}
      container
      sx={{
        border: "solid 1px #434842",
        backgroundColor: "#1F201E",
        borderRadius: 3,
        mb: 7,
      }}
    >
      <Grid item xs={12} md={6} sm={6}>
        <Box sx={{ px: { md: 8, sm: 5, xs: 3 }, py: { md: 0, sm: 0, xs: 2 } }}>
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: { md: 30, sm: 22, xs: 20 },
              py: 2.5,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              pb: { md: 0, sm: 0, xs: 2.5 },
              fontSize: { md: "inherit", sm: "inherit", xs: 12 },
            }}
          >
            {description}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Box>
          <VideoContainer source={videoSource} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default FeatureBox;
