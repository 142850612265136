import { Box, Typography } from "@mui/material";
import React from "react";

const PricingBox = ({ title, description, button, price }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        border: "solid 1px #434842",
        backgroundColor: "#1F201E",
        borderRadius: 3,
        py: 5,
        px: 5,
        width: 250,
      }}
    >
      <Typography sx={{ fontWeight: 700, fontSize: 20 }}>{title}</Typography>
      <Box sx={{ pt: 4, pb: 20 }}>{description}</Box>
      <Typography sx={{ fontWeight: 700, fontSize: 20, textAlign: "center" }}>
        {" "}
        ${price} / month
      </Typography>

      <Box display="flex" justifyContent="center" sx={{ pt: 5 }}>
        {button}
      </Box>
    </Box>
  );
};

export default PricingBox;
