import React from "react";
import { Box } from "@mui/material";

const VideoContainer = ({ source }) => {
  return (
    <Box>
      <video
        src={source}
        autoPlay
        loop
        playsInline
        muted
        controls={0}
        title="Synapse Tutor"
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 10,
        }}
        onError={(e) => console.log("Error playing video", e)}
      />
    </Box>
  );
};

export default VideoContainer;
